body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dot-group {
  display: flex;
  align-items: center;
  justify-content: center;

  .carousel__dot {
    width: 14px;
    height: 3px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #e1e1e1;
    border: none;
    border-radius: 50%;
    margin: 0 4px;
    padding: 0;
    background-color: #c3c4ca;
  }

  /* This class is found in DotGroup from pure-react-carousel */
  /* We need to override it to add our styles */
  .carousel__dot--selected {
    width: 24px;
    height: 4px;
    flex-shrink: 0;
    border-radius: 2px;
    background: #0e6bb7;
    transition: background 0.4s ease;
  }
}
input[type="number"] {
  -moz-appearance: textfield;
}
