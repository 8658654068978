.v3-statement-summary-container {
  position: relative;
  height: 100vh;
}

.v3-accordion-nav {
  background-color: "#4e4e4e";
}

.v3-accordion-body {
  color: "blue";
}

.v3-statement-billing-display-section {
  display: flex;
  padding: 24px 20px 0px 20px;
  justify-content: space-between;
  align-items: center;
}

.v3-statement-billing-left-section {
  display: flex;
  gap: 12px;
  align-items: center;
}

.v3-statement-billing-data-text {
  font-weight: 400;
  font-size: 0.75rem;
  color: var(--primary-base-color-2, #697a92);
}

.v3-billing-cycle-date-month {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--primary-base-color-1, #0d2950);
  padding-right: 2px;
}

.v3-statement-billing-date-interval {
  font-size: 0.75rem;
  color: var(--primary-base-color-3, #a8b2c0);
}

/* summary widget section */
.v3-statement-summary-widget-section {
  padding: 18px;
  border: 1px solid#D3D8DF;
  border-radius: 18px;
  box-shadow: 0px 10px 31px -2px #00000017;
  /* position: absolute; */
  position: relative;
  /* top: 30px;
  left: 0;
  right: 0; */
  margin-left: auto;
  margin-right: auto;
  width: 91%;
  background-color: #ffffff;
}

.v3-statement-summary-widget-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.v3-widget-total-amount-due-text,
.v3-widget-minimum-amount-due-text,
.v3-statement-summary-due-date-text,
.v3-widget-remaining-amount-due-text {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--tertiary-neutral-color-2, #a8a8a8);
}

.v3-statement-summary-widget-mid-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding: 10px 8px 18px 8px;
}

.v3-widget-total-amount-due-data,
.v3-widget-minimum-amount-due-data,
.v3-statement-summary-due-date-data {
  font-size: 1rem;
  font-weight: 700;
  color: var(--tertiary-neutral-color-1, #0e0e0e);
}

.v3-widget-minimum-amount-due-data {
  float: right;
}

.v3-widget-remaining-amount-due-data {
  float: right;
  font-size: 1rem;
  font-weight: 700;
  color: var(--secondary-color-1, #ed5b27);
}

.v3-statement-summary-widget-bottom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.v3-statement-summary-widget-cta-button {
  padding: 10px 18px 10px 18px;
  border-radius: 12px;
  background-color: var(--secondary-color-1);
  font-size: 1rem;
  color: var(--leading-white);
  font-weight: 800;
  cursor: pointer;
}

.v3-statement-summary-widget-mid-section {
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: var(--primary-base-color-5);
  padding: 10px 8px 10px 8px;
}

.v3-statement-summary-highlights-seciton {
  display: flex;
  justify-content: space-between;
  width: 98%;
  align-items: center;
}

.v3-statement-summary-highlights-left-section {
  display: flex;
  gap: 4px;
  align-items: center;
}

.v3-highlights-text {
  font-size: 0.725rem;
  color: var(--primary-base-color-2, #697a92);
  font-weight: 700;
}

/* nudge section */
.v3-statement-summary-nudge-section {
  position: absolute;
  top: 161px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--primary-base-color-5, #f0f2f4);
  height: 136px;
  z-index: -1;
}

.v3-statement-summary-nudge {
  display: flex;
  gap: 8px;
  padding-top: 105px;
}

.v3-statement-summary-reward-nudge {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 50%;
  border: 1px solid #d3d8df;
  border-radius: 22px;
  box-shadow: 0px 4px 24px 0px #00000024;
  padding: 12px 14px 12px 14px;
  background-color: #ffffff;
}

.v3-nudge-left-section {
  display: flex;
  align-items: center;
  gap: 4px;
}

.v3-statement-summary-cashback-nudge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  border: 1px solid #d3d8df;
  border-radius: 22px;
  box-shadow: 0px 4px 24px 0px #00000024;
  padding: 12px 14px 12px 14px;
  background-color: #ffffff;
}

.v3-reward-nudge-text {
  color: #0e0e0e;
  font-weight: 700;
  font-size: 0.872rem;
  padding-top: 3px;
  width: 23%;
}

.v3-cashback-nudge-text {
  color: #0e0e0e;
  font-weight: 700;
  font-size: 0.872rem;
  padding-top: 3px;
  width: 32%;
}

.v3-nudge-reward-text {
  color: #697a92;
  font-weight: 700;
  font-size: 0.725rem;
  padding-top: 2px;
}

.v3-nudge-cashback-text {
  color: #697a92;
  font-weight: 700;
  font-size: 0.725rem;
  padding-top: 2px;
}

.v3-statement-summary-reward-cashback-section {
  width: 100%;
}

.v3-statement-summary-reward-only-nudge-section {
  width: 100%;
}

.v3-statement-summary-reward-only-nudge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d3d8df;
  border-radius: 22px;
  box-shadow: 0px 4px 24px 0px #00000024;
  padding: 12px 18px 12px 18px;
  background-color: #ffffff;
}

.v3-statement-summary-cashback-only-nudge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d3d8df;
  border-radius: 22px;
  box-shadow: 0px 4px 24px 0px #00000024;
  padding: 12px 18px 12px 18px;
  background-color: #ffffff;
}

.v3-cashback-only-nudge-text {
  width: 14%;
  color: #0e0e0e;
  font-weight: 800;
  font-size: 0.872rem;
  padding-top: 3px;
}

.v3-reward-only-nudge-text {
  color: #0e0e0e;
  font-weight: 800;
  font-size: 0.872rem;
  padding-top: 3px;
}

.v3-statement-summary-bottom-sheet-section {
  width: 100%;
}

.v3-summary-bottom-sheet-header-text {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  font-weight: 600;
  font-size: 1.125rem;
}

.v3-summary-bottom-sheet-close-icon {
  /* background-color: #F1F1F1;
  border-radius:100%;
  padding:4px; */
}

.v3-summary-bottom-sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 26px 28px 28px;
}

.v3-summary-bottom-sheet-body {
  padding: 18px 10px 18px 10px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  overflow-y: scroll;
  max-height: 275px;
}

.v3-summary-bottom-sheet-body-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 8px 30px;
  min-height: 40px;
  border-radius: 8px;
}

.v3-summary-bottom-sheet-body-date-value {
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  font-weight: 400;
  font-size: 0.875rem;
}

.selected-date {
  color: var(--secondary-color-1);
  font-weight: 700;
}

.selected-cycle {
  background-color: var(--secondary-color-5);
}

.v3-summary-bottom-sheet-cta-button {
  padding: 16px;
}

/* highlights section  */
.v3-statement-summary-highlights-spends-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v3-statement-summary-highlights-emis-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v3-emis-tab-label {
  display: flex;
  gap: 4px;
  align-items: center;
}

.v3-statement-summary-highlights-fees-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v3-fees-tab-label {
  display: flex;
  gap: 4px;
  align-items: center;
}

.v3-statement-summary-highlights-spends-tab,
.v3-statement-summary-highlights-emis-tab,
.v3-statement-summary-highlights-fees-tab {
  padding: 6px 10px 6px 10px;
  background-color: #f0f2f4;
  border-radius: 8px;
}

.v3-spends-tab-label,
.v3-emis-tab-label,
.v3-fees-tab-label {
  color: var(--primary-base-color-2, #697a92);
  font-size: 0.75rem;
  font-weight: 500;
}

.v3-spends-tab-value,
.v3-emis-tab-value,
.v3-fees-tab-value {
  color: var(--primary-base-color-1, #0d2950);
  font-size: 0.75rem;
  font-weight: 700;
}

.v3-statement-summary-highlights-dropdown-section {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.v3-emis-tab-count,
.v3-fees-tab-count {
  background-color: #0d2950;
  border-radius: 100%;
  padding: 2px 3px 2px 3px;
  font-size: 0.55rem;
  color: #f0f2f4;
  width: 16px;
  height: 16px;
  text-align: center;
}

.v3-statement-summary-highlights-download-statement {
  text-align: center;
  padding: 11px 5px 5px 5px;
  font-size: 0.625rem;
  color: #1965cf;
  font-weight: 700;
  cursor: pointer;
}

/* transaction section  */
/* .v3-transactions-section{
  position: absolute;
  top: 525px;
  padding:28px 20px 30px 20px;
  width: 100%;
}

.v3-transactions-section-header{
  padding-bottom: 10px;
  font-size: 1rem;
  font-weight: 700;
  color:#0D2950;
}

.v3-transaction-section{
  display: flex;
  justify-content: space-between;
}

.v3-transaction-content{
  position: relative;
  border-bottom: 1px solid #F1F1F1;
  padding-top: 12px;
  padding-bottom: 12px;
}

.v3-transaction-left-section{
  display: flex;
  align-items: center;
  gap: 12px;
}

.v3-transaction-title{
  font-size: 0.85rem;
  color: #0E0E0E;
  font-weight: 600;
}

.v3-transaction-created-date{
  font-size: 0.85rem;
  color: #A8A8A8;
  font-weight: 500;
}

.v3-transaction-amount{
  font-size: 0.875rem;
  font-weight: 600;
  color: #0E0E0E;
}

.v3-transaction-reward-point{
  font-size: 0.625rem;
  font-weight: 600;
  color: #1965CF;
  float: right;
  background-color: #F1F6FC;
  border-radius: 50px;
  padding: 2px 4px 2px 4px;
}

.v3-transaction-cashback-amount{
  font-size: 0.625rem;
  font-weight: 600;
  color:#23951C;
  background-color: #F2F9F1;
  border-radius: 50px;
  padding: 2px 4px 2px 4px;
}

.v3-transaction-right-section{
  display: flex;
  flex-direction: column;
  align-items: end;
}

.v3-transaction-emi-failed-nudge{
  padding: 8px 12px 8px 12px;
  background-color: #FEF2F2;
  border-radius: 8px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v3-transaction-emi-failed-nudge-left-section{
  display: flex;
  align-items: center;
  gap: 4px;
}

.v3-transaction-emi-failed-text{
  font-size: 0.75rem;
}

.v3-transaction-emi-failed-logo-retry-text{
  font-weight: 700;
  color:#0E0E0E;
}

.v3-transaction-emi-failed-retry-logo{
  height: 28px;
}

.v3-transaction-emi-failed-logo{
  height: 28px;
} */

/* view all transactions */
/* .v3-view-all-transaction-button{
  position: absolute;
  bottom: 0px;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  text-align: center;
  text-decoration: underline;
  color: #ED5B27;
  cursor: pointer;
  font-size: 0.875rem;
} */
.v3-statement-summary-mid-section {
  position: relative;
  padding: 28px 0px;
}

.v3-statement-summary-mid-section-semi-background {
  position: absolute;
  background-color: var(--primary-base-color-5);
  height: 94px;
  width: 100%;
  bottom: 0;
}

.v3-statement-summary-widget-all-clear-section,
.v3-statement-summary-widget-past-cycle-pending {
  text-align: center;
  padding-top: 18px;
}

.v3-statement-summary-widget-all-clear-text {
  color: var(--tertiary-neutral-color-2, #a8a8a8);
  font-size: 0.75rem;
}

.v3-statement-summary-widget-past-cycle-pending-text {
  font-size: 0.75rem;
  color: var(--tertiary-neutral-color-2, #a8a8a8);
}

.v3-statement-summary-widget-all-clear-greetings {
  color: var(--tertiary-positive-color-1, #23951c);
  font-size: 0.875rem;
}

.v3-statement-summary-widget-past-cycle-pending-warning-text {
  font-size: 0.875rem;
  color: var(--tertiary-neutral-color-1, #0e0e0e);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v3-statement-summary-widget-view-latest-bill {
  color: var(--secondary-color-1, #ed5b27);
  font-size: 0.625rem;
  font-weight: 700;
  cursor: pointer;
}

.v3-statement-summary-widget-past-cycle-warning-icon {
  font-size: 0.625rem;
}
/* Download statement bottom sheet  */
.v3-statement-summary-download-button-close-section {
  width: 100%;
  height: 20px;
}
.v3-statement-summary-download-bottom-sheet-section {
  padding: 34px 32px 36px 32px;
}

.v3-statement-summary-download-bottom-sheet-close-button {
  float: right;
  background-color: #f1f1f1;
  padding: 5px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-right: 18px;
}

.v3-statement-summary-download-bottom-sheet-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
}

.v3-statement-summary-download-bottom-sheet-mid-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.v3-statement-summary-download-bottom-sheet-password-protected-section {
  background-color: #f2f9f1;
  padding: 4px 12px 4px 12px;
  border-radius: 30px;
  color: #23951c;
  font-weight: 700;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 2px;
}

.v3-statement-summary-download-bottom-sheet-description-section {
  text-align: center;
  font-size: 0.875rem;
  color: #697a92;
}

.v3-statement-summary-download-bottom-sheet-description-bold {
  font-weight: 700;
}

.v3-statement-summary-download-bottom-sheet-bottom-section {
  border-top: 1px solid #f1f1f1;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.v3-statement-summary-download-bottom-sheet-send-email-button {
  padding-bottom: 8px;
}

.v3-statement-summary-download-bottom-sheet-text {
  font-size: 1.125rem;
  font-weight: 700;
  color: #0e0e0e;
  text-align: center;
}

.v3-statement-summary-download-statement-success-text {
  font-size: 0.75rem;
  color: #697a92;
  font-weight: 500;
  padding-top: 8px;
}
