.table-header {
  font-family: "Inter";
  font-style: normal;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  padding: 8px;
  color: #23212c;
  width: 40px;
}

.table-cell {
  font-family: "Inter";
  font-style: normal;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  padding: 20px 8px;
  color: #23212c;
  width: 40px;
}

/* input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #ec5b24;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
} */

.input_internal {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  position: absolute;
  top: 38%;
  left: 14%;
  background-color: #ec5b24;
}
