.animation-tilt-shaking {
  animation-iteration-count: 4;
  animation-duration: 1.5s;
  animation-name: tilt-shaking;
  animation-delay: 1s;
}
@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(1deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-1deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.animation-grow {
  animation-iteration-count: 2;
  animation-duration: 2s;
  animation-name: scale;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animation-strip {
  position: absolute;
  display: block;
  top: -120px;
  left: 0;
  width: 0px;
  height: 0px;
  transform: rotate(45deg);
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.393),
    rgba(255, 255, 255, 0.393)
  );
  z-index: 10;
  animation: slide-strip 2s ease-in-out 2;
}
@keyframes slide-strip {
  0% {
    transform: translateX(-800%) rotate(45deg);
    display: block;
    width: 30px;
    height: 600px;
  }
  100% {
    transform: translateX(800%) rotate(45deg);
    display: block;
    width: 30px;
    height: 600px;
  }
}
